.VerticalMenu {
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.VerticalMenu a {
    margin: 0.5pc;
}

@media only screen and (max-width: 600px) {

    .VerticalMenu a {
        margin: 0.1pc;
        margin-right: 0.5pc;
    }

    .VerticalMenu a > svg {
        height: 4vh;
    }
}