.HeaderSplash-Jumbotron {
    margin: 0;
    height: 40vh;
    background-color: transparent;
}

.HeaderSplash-Container {
    height: -webkit-fill-available;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    text-align: -webkit-center;;
}

.HeaderSplash-Container > p {
    color: white;
}

.HeaderContacts {
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    width: 70%;
}

.HeaderContacts > span > a {
    color: white;
}

.HeaderContacts > span > a > svg {
    margin: 1pc;
}

.HeaderSplash-Name {
    width: 70%;
}

@media only screen and (max-width: 600px) {

    .HeaderContacts {
        display: none;
    }

    .HeaderSplash-Jumbotron {
        height: 20vh;
    }
}