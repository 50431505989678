.ContactForm {
    padding: 4pc;
}

.ContactForm {
    color: lightgray;
}

@media only screen and (max-width: 600px) {

    .ContactForm {
        padding: 1pc;
    }

    .form-row {
        display: flex;
        flex-flow: column;
    }
}