.Header {
    position: relative;
}

.Header > div,
.Header > nav {
    margin-left: 15vw;
    margin-right: 15vw;
}

.Header > div {
    padding-top: 5vh;
    padding-bottom: 5vh;
    padding-left: 2vw;
    padding-right: 2vw;
    background-color: transparent;
}

.ContentTabs {
    border-bottom: 3px solid #dee2e6;
}

.ContentTabs .nav-link {
    color: lightgray;
    border-color: transparent;
}

.ContentTabs .nav-link.active {
    background-color: transparent;
    border-color: lightgray;
    color: lightgray;
}

.ContentTabs .nav-link:hover {
    background-color: #5D5C61;
    border-color: transparent;
    color: lightgray;
}

.btn-primary {
    background-color: transparent;
    border-color: lightgray;
}

.btn-primary:hover {
    background-color: #5D5C61;
    border-color: transparent;
    color: lightgray;
}

h1,
h2,
p {
    color: lightgray;
}

@media only screen and (max-width: 600px) {
    .ContentTabs {
        display: block;
    }

    .Header > div {
        padding-right: 2.5pc;
        padding-left: 2.5pc;
    }
}
