.MoreButton {
    margin: 1pc;
}

.col {
    width: 30vw;
}

.CardDivider {
    height: 3px;
    background-color: #5D5C61;
}

.Card {
    background-color: transparent;
}

.CardBodyText {
    display: flex;
    flex-flow: column;
    justify-content: center;
    color: lightgray;
    background-color: rgba(0, 0, 0, 0.4);
    height: 20vh;
}

.CardBodyText p {
    color: lightgray;
}

@media only screen and (max-width: 600px) {

    .col {
        width: 100vw;
    }

    .row {
        flex-flow: column;
    }

    .Card {
        margin: 0;
    }

    iframe {
        height: 40vh;
    }

    .Header > div {
        margin: 0;
    }

    .Examples {
        font-size-adjust: 0.5;
    }
}
