.MyInfo {
    position: fixed;
    left: 0;
    bottom: 0;
    margin: 1pc;
    height: 5vh;
}

.MyInfo {
    display: flex;
    flex-flow: row;
    align-items: center;
}

.IconText {
    display: none;
}

.IconText > p {
    margin: 0;
    margin-left: 1pc;
}

.MyInfo a:hover + .IconText {
    display: inline;
}

.BuiltBy {
    color: lightgray;
}