.MediaPage {
    color: lightgray;
}

.FeaturedArticlesBlock,
.FeaturedArticlesBlock > div {
    padding: 1pc;
    text-align: -webkit-center;
}

.RestOfMedia {
    padding-top: 1pc;
    text-align: -webkit-center;
}

.RestOfMedia .col {
    padding: 1pc;
}

.FeaturedArticlesBlock > .row > .col > a,
.RSSFeedBlock > .row > .col > a {
    background-color: rgba(0, 0, 0, 0.4);
    color: lightgray;
    border: none;
}

@media only screen and (max-width: 600px) {

    .FeaturedArticlesBlock {
        flex-flow: column;
    }

    .FeaturedArticlesBlock > div {
        padding: 0;
    }

    .col {
        padding-bottom: 1pc;
    }
}