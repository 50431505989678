.Card {
    margin: 1pc;
    border: none;
    width: inherit;
}

.CardBody {
    display: flex;
    flex-flow: column;
    width: inherit;
}

.CardBody div {
    width: inherit;
}

.YoutubeVideo {
    padding: 1pc;
    width: 100%;
}
